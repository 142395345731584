
// Обновление значения поля ввода и текста в rs-bullet при изменении ползунка
document.getElementById('rs-range-line').addEventListener('input', function () {
    document.getElementById('positionsCount').value = this.value;
    document.getElementById('rs-bullet').textContent = this.value;
});

// Обновление значения ползунка и текста в rs-bullet при изменении поля ввода
document.getElementById('positionsCount').addEventListener('input', function () {
    var value = Number(this.value);
    document.getElementById('rs-range-line').value = value;
    document.getElementById('rs-bullet').textContent = value;
});

// Стоимость услуг
const costPerPosition = 100; // стоимость за позицию
const costForSpecification = 180; // стоимость за спецификацию
const costForWorkStatement = 150; // стоимость за ведомость объемов работ
const costForDrawing = 350; // стоимость за чертежи без спецификации
const costForNoData = 450; // стоимость за отсутствие исходных данных
const costForExpertise = 50000; // стоимость за экспертизу
const costForPriceAnalysis = 250; // стоимость за конъюнктурный анализ цен
const minimumCost = 5000; // минимальная стоимость

// Функция для расчета скидки
function calculateDiscount(positionsCount) {
    let discount = 0;

	if (positionsCount > 10000) {
        discount = 0.25; // 25% скидка для более чем 10000 позиций
    } else if (positionsCount > 5000) {
        discount = 0.2; // 20% скидка для более чем 5000 позиций
    } else if (positionsCount > 3000) {
        discount = 0.15; // 15% скидка для более чем 3000 позиций
    } else if (positionsCount > 1000) {
        discount = 0.1; // 10% скидка для более чем 1000 позиций
    }
	

    return discount;
}

// Функция для расчета стоимости с учетом скидки
function calculateCostWithDiscount() {
    let positionsCount = document.getElementById('positionsCount').value;
    let dataBasis = document.querySelector('input[name="dataBasis"]:checked').value;
    let workType = document.querySelector('input[name="workType"]:checked').value;
    let needExpertise = document.querySelector('input[name="needExpertise"]:checked').value === "true";
    let needPriceAnalysis = document.querySelector('input[name="priceAnalysis"]:checked').value === "true";

    let cost = 0;

    switch (dataBasis) {
        case "спецификации":
            cost = positionsCount * costForSpecification;
            break;
        case "ведомости объемов работ":
            cost = positionsCount * costForWorkStatement;
            break;
        case "чертежи без спецификации":
            cost = positionsCount * costForDrawing;
            break;
        case "исходные данные отсутствуют":
            cost = positionsCount * costForNoData;
            break;
    }

    if (needPriceAnalysis) {
        cost += costForPriceAnalysis * (positionsCount * 0.35);
    }

    if (needExpertise) {
        if (cost > 800000) {
            cost += cost * 0.25;
        } else if (cost > 300000) {
            cost += cost * 0.35;
        } else {
            cost += 50000;
        }
    }

    cost = Math.max(cost, minimumCost);

    // Расчет скидки
    let discount = calculateDiscount(positionsCount);
    let discountedCost = cost - (cost * discount);

    const formattedCost = discountedCost.toLocaleString('ru-RU', { maximumFractionDigits: 0 });

    document.getElementById('result').innerText = "Предварительная стоимость разработки сметы: " + formattedCost + " руб.";

    document.getElementById('emailModal').style.display = 'block';
}

document.getElementById('calculateButton').addEventListener('click', calculateCostWithDiscount);

var rangeSlider = document.getElementById("rs-range-line");
var rangeBullet = document.getElementById("rs-bullet");

rangeSlider.addEventListener("input", showSliderValue, false);

function showSliderValue() {
    rangeBullet.innerHTML = rangeSlider.value;
    var bulletPosition = (rangeSlider.value / rangeSlider.max);
    rangeBullet.style.left = (bulletPosition * 75) + "%";
}


